import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import 'moment/locale/es';

@Pipe({
  name: 'toLocalScl'
})
export class ToLocalSclPipe implements PipeTransform {

  transform(value: any, utc, format): any {
    if(utc){
      return moment(value).utcOffset(utc).format(format);
    }else{
      return moment(value).tz("America/Santiago").format(format);
    }
  }

}
