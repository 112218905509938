import { Component, OnInit, HostListener } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-header-v3',
  templateUrl: './header-v3.component.html',
  styleUrls: ['./../../../../assets/header-footer-v3.scss']
})
export class HeaderV3Component implements OnInit {

  constructor(
    public utils:UtilsService
  ) { }

  ngOnInit() {
    
  }

}
