import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OrdenesCentros } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'reservaApp';
  isHeaderFooter = true;
  superLoading = true;
  isAuth = false;
  authSaludIntegral;
  counterParams = 0;
  loader;

  constructor(
    public utils:UtilsService,
    public router:Router,
    public activateRoute: ActivatedRoute
  ){

  }

  ngOnInit() {
    this.setAuthSaludIntegral();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          window.scrollTo(0,0);
      }
    });
  }

  ngAfterViewInit(): void {
    this.displayHeaderAndFooter();
  }

  displayHeaderAndFooter(){

    this.activateRoute.queryParams.subscribe( params => {

      if(Object.keys(params).length){
        clearTimeout(this.loader);
        if(params['displayHeaders'] && params['displayHeaders'] === 'false'){
          this.isHeaderFooter = false;
        }
  
        if(params['auth']){
          this.isAuth = true;
        }
  
        if(!this.isAuth){
          this.closeLoading();
        }

      }else{
        this.closeLoading();
      }

    });
  }

  closeLoading(){
    this.loader = setTimeout(()=> {
      this.superLoading = false;
    },2000);
  }

  setAuthSaludIntegral(){
    this.authSaludIntegral = this.utils.actionAuthSaludIntegral().get().subscribe( val => {
      if(val){
        this.superLoading = false;
      }
    })
  }
}
