import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AgendaAmbulatoriaService } from 'src/app/services/agenda-ambulatoria.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-adjuntar-examenes',
  templateUrl: './adjuntar-examenes.component.html',
  styleUrls: [
    './adjuntar-examenes.component.scss',
    '../busqueda/busqueda.component.scss'
  ]
})
export class AdjuntarExamenesComponent implements OnInit, OnDestroy {

  listadoFicheros: any = [];
  tipoDocumentos = ['RESULTADOS DE EXÁMENES', 'FOTOGRAFÍAS', 'INFORMES', 'OTROS'];
  loaded = false;
  adjuntarExamen;
  @Output() emitterAction: EventEmitter<any> = new EventEmitter();
  @Input() dataExamenesAdjunto: any;

  constructor(
    public utils: UtilsService,
    public agendaService: AgendaAmbulatoriaService
  ) { }

  ngOnInit() {

    this.adjuntarExamen = this.utils.actionAdjuntarExamen().get().subscribe(r => {
      this.listadoFicheros = [];
      this.loaded = false;
    });
  }

  ngOnDestroy() {
    this.adjuntarExamen.unsubscribe();
  }

  openInputFile() {
    //if(this.listadoFicheros.length < 5){
    document.getElementById('fichero').click();
    //}
  }


  fileChange(files: File[]) {

    /*  if ((this.listadoFicheros.length + files.length) > 5) {
        this.utils.mDialog('Error', 'Solo se permiten 5 documentos por solicitud.', 'message');
        return false;
      }*/

    Object.keys(files).forEach(a => {
      this.prepareFile(files[a],);
    });
    let val: any = document.getElementById('fichero');
    val.value = null;

  }

  prepareFile(file: any) {

    let reader = new FileReader();
    let size = Math.round((file['size'] / 1000) * 100) / 100;

    if (size < 20000) {

      reader.readAsDataURL(file);
      reader.onload = () => {

        let file64 = String(reader.result).split(";");
        let fichero = String(reader.result);
        let mimetype = file64[0].split(":")[1];
        const mimeTypeAllowed = mimetype == "image/jpg" || mimetype == "image/jpeg" || mimetype == "image/gif" || mimetype == "image/png" || mimetype == "application/pdf";

        if (mimeTypeAllowed) {

          this.listadoFicheros.push({
            fichero: fichero,
            nombre: file.name,
            categoria: null,
            file64,
            mimetype,
            fileType: mimetype.includes('image') ? 'IMAGE' : 'PDF'
          });

        } else {
          this.utils.mDialog("Error", "Algunos archivos no se cargaron por ser de tipo no permitido", "message");
        }
      };
    } else {
      this.utils.mDialog("Error", "Algunos archivos no se cargaron por ser mayor a 5MB", "message");
    }

  }

  borrarFichero(i) {
    this.utils.mDialog('Estimado paciente', '¿Está seguro que desea remover el examen?', 'confirm').subscribe(res => {
      if (res) {
        this.listadoFicheros.splice(i, 1);
      }
    })
  }

  cargar() {

    const checkEmptyCategoria = this.listadoFicheros.filter(item => {
      return !item.categoria
    });

    if (checkEmptyCategoria.length > 0) {
      this.utils.mDialog("Estimado paciente", "Debe indicar la categoría del documento que desea cargar. Revise e intente de nuevo.", "message");
      return;
    }

    this.utils.showProgressBar();


    const datosAdjuntos: any = {
      idmedico: this.dataExamenesAdjunto.idMedico,
      idpaciente: this.dataExamenesAdjunto.datosCita.paciente.id,
      rutpaciente: this.dataExamenesAdjunto.idPaciente,
      emailmedico: this.dataExamenesAdjunto.email,
      appointmentId: this.dataExamenesAdjunto.datosCita.idCita,
      file: this.listadoFicheros.map(item => {
        return {
          nombrearchivo: item.nombre,
          tipo: this.getTipoDato(item.mimetype).toLowerCase(),
          data: item.file64[1].split(',')[1],
          categoria: item.categoria.toLowerCase()
        }
      })
    };

    this.agendaService.adjuntarExamenes(datosAdjuntos).then(res => {
      this.utils.hideProgressBar();
      this.loaded = true;
      this.listadoFicheros = [];
    }).catch(err => {
      this.utils.hideProgressBar();
      this.utils.mDialog('Estimado paciente', 'Se ha producido un error al intentar adjuntar los exámenes. Intente más tarde.', 'message');
    })

  }

  reinicio() {
    this.emitterAction.emit('REINICIO');
  }

  getTipoDato(mimetype) {
    switch (mimetype) {
      case 'image/jpg': return 'JPG'; break;
      case 'image/jpeg': return 'JPEG'; break;
      case 'image/gif': return 'GIF'; break;
      case 'image/png': return 'PNG'; break;
      case 'application/pdf': return 'PDF'; break;
    }
  }

}
