import { Component } from '@angular/core';
import { version } from "package.json";

@Component({
  selector: 'app-footer-v3',
  templateUrl: './footer-v3.component.html',
  styleUrls: ['./../../../../assets/header-footer-v3.scss']
})
export class FooterV3Component {
  version = version;

}
