import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { ENV } from 'src/environments/environment';
import { AgendaAmbulatoriaService } from 'src/app/services/agenda-ambulatoria.service';
import { OrderPipe } from 'ngx-order-pipe';
import * as clone from 'clone';
import gtag, { install } from 'ga-gtag';

@Component({
  selector: 'app-medicos-asociados',
  templateUrl: './medicos-asociados.component.html',
  styleUrls: ['./medicos-asociados.component.scss']
})
export class MedicosAsociadosComponent implements OnInit, OnDestroy {

  @Input() medicosAsociados = [];
  @Input() title = 'REVISA QUÉ OTROS MÉDICOS TIENEN HORAS DISPONIBLES';
  @Input() fromSaludIntegral = false;

  subMedicos;
  detalleBusqueda;
  counterLoader = 0;
  compensacion = -240;
  recursos = [];
  displayRecursos = false;
  loading = true;
  retryDone = false;

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  };

  constructor(
    public utils: UtilsService,
    public agendaService: AgendaAmbulatoriaService,
    public orderPipe: OrderPipe
  ) { }

  ngOnInit() {

    this.subMedicos = this.utils.getDataProfesionalRelacionado().subscribe(data => {
      this.detalleBusqueda = data;
      this.loading = true;
      this.displayRecursos = false;
      this.getRecursosRelacionados();
    });

  }

  ngOnDestroy() {
    this.subMedicos.unsubscribe();
  }

  buscarCalendario(re) {

    const region = this.detalleBusqueda.centroAtencion.idRegion;
    let detalleBusqueda = clone(this.detalleBusqueda);
    detalleBusqueda['fromMedicosRelacionados'] = true;

    detalleBusqueda.profesional = {
      detalle: re.nombre,
      esProfesional: true,
      idProfesional: re.id,
      informacionAdicional: re.informacionAdicional,
      nombreProfesional: re.nombre,
      urlImagenProfesional: re.urlImagenProfesional
    }

    detalleBusqueda.centro = {
      direccion: { calle: null, numero: null, piso: null, comuna: 'Región Metropolitana' },
      horaApertura: null,
      horaCierre: null,
      idCentro: region,
      idRegion: region,
      latitud: null,
      longitud: null,
      nombre: "Todos",
      codigo: 'todos',
      detalle: 'Todos'
    }

    detalleBusqueda.fromSaludIntegral = this.fromSaludIntegral;
    detalleBusqueda.fromMedicosRelacionados = true;

    this.utils.setBuscarProfesionalRelacionado(detalleBusqueda);
    gtag('config', ENV.analyticsCode,
      { 'page_path': `/busqueda/profesionalRecomendado/area/${this.detalleBusqueda.area.id}/profesional/${this.detalleBusqueda.profesional.idProfesional}/servicio/${this.detalleBusqueda.especialidad.idServicio}/centro/${this.detalleBusqueda.centroAtencion.idCentro}` });

    this.loading = true;
    this.displayRecursos = false;
    gtag('event', 'Profesional Relacionado', { 'event_category': 'Horas Disponible', 'event_label': 'Ver Horas', 'value': '0' });

    this.agendaService.crearMarca({
 dni:null,
      dni_type: null,
      element: 'VER HORAS DE PROFESIONALES RELACIONADOS',
      value: '',
      detail: 'SE HACE CLIC EN VER HORAS DE PROFESIONALES RELACIONADOS',
      source: 'BÚSQUEDA'
    });

  }

  getRecursosRelacionados() {

    return new Promise((resolve, reject) => {

      let fechaHoy = new Date();
      let fechaLimite = new Date();
      fechaLimite.setDate(fechaHoy.getDate() + 14);
      fechaLimite.setHours(23);
      fechaLimite.setMinutes(59);
      fechaLimite.setSeconds(59);

      const nombreCentro = this.detalleBusqueda.centroAtencion.nombre.toUpperCase();
      const idCentro = nombreCentro === 'TODOS' ? this.detalleBusqueda.centroAtencion.idRegion : this.detalleBusqueda.centroAtencion.idCentro

      this.agendaService.getRecursos({
        todosCentro: nombreCentro === 'TODOS' ? true : false,
        idCentro: idCentro,
        fechaInicio: this.utils.trDateStr(fechaHoy, null, this.compensacion),
        fechaTermino: this.utils.trDateStr(fechaLimite, null, this.compensacion),
        idServicio: this.detalleBusqueda.especialidad.idServicio,
        idPlanSalud: this.fromSaludIntegral ? ENV.idPlanSaludAdministrada : ENV.idPlanSaludInit,
        idProfesional: null,
        idPaciente: this.detalleBusqueda.documentoPaciente.idPaciente,
        fromProfRel: true
      }).subscribe((res: any) => {

        let data = this.filtrarRecursosSoloProfesional(JSON.parse(JSON.stringify(res)));
        let recursos = [];

        if (res.hasOwnProperty('listaRecursos') && data['listaRecursos'] && data['listaRecursos'].length > 0) {

          data['listaRecursos'].forEach((val, key) => {
            data['listaRecursos'][key]['proximaHoraEpoch'] = val['proximaHoraDisponible']['cupo']['horaEpoch'];
            if (val['id'] !== this.detalleBusqueda.profesional.idProfesional) {
              recursos.push(data['listaRecursos'][key]);
            }
          });

          this.recursos = this.orderPipe.transform(recursos, 'proximaHoraEpoch');
          this.loading = false;
          this.displayRecursos = true;

        } else {

          if (!res.hasOwnProperty('listaRecursos') && !this.retryDone && res.statusCod !== 'OK') {
            this.retryDone = true;
            this.getRecursosRelacionados();
          } else {
            this.loading = false;
            this.displayRecursos = false;
          }

        }

        resolve(data);

      })
    })

  }

  filtrarRecursosSoloProfesional(data) {

    let recursos = [];
    if (data['listaRecursos']) {
      data['listaRecursos'].forEach((val, key) => {
        if (val['tipoRecurso'].toLowerCase() != 'room') {
          recursos.push(val);
        }
      })
    }
    data['listaRecursos'] = recursos;
    return data;
  }
}
